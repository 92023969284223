
import { defineComponent, onMounted } from "vue";
import ProjectsTable from "@/components/projects/ProjectsTable.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "widgets-tables",
  components: {
    ProjectsTable,
  },
  data() {
    return {
      projects: {},
    };
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("All Projects");
      MenuComponent.reinitialization();
    });
  },

  methods: {
    async getAllProjects() {
      let list = {};
      ApiService.setHeader();
      this.projects = await ApiService.get("monday/board/items")
        .then(({ data }) => {
          return data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
  },

  mounted() {
    this.getAllProjects();
  },
});
